<template>
  <div :id="'progressbar-' + id" class="progressbar"></div>
</template>

<script>
import ProgressBar from "progressbar.js";

export default {
  setup(props) {
    return {
      bar: null,
      id: props.id,
    };
  },
  props: {
    id: Number,
    porcentagem: Number,
  },
  mounted() {
    this.bar = new ProgressBar.Line(`#progressbar-${this.id}`, {
      strokeWidth: 4,
      easing: "easeInOut",
      duration: 1400,
      color: "#82D616",
      trailColor: "#eee",
      trailWidth: 2,
      svgStyle: { width: "100%", height: "100%" },
    });

    this.bar.animate(Number(this.porcentagem));
  },
};
</script>

<style scoped>
.progressbar {
  width: 100%;
  height: 10px;
  margin-bottom: 4px;
}
</style>
