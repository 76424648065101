<template>
  <div class="card" style="max-height: 300px">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0">Franquias</h6>
    </div>
    <div class="card-body pt-2" style="overflow-y: auto">
      <ul class="list-group">
        <li
          v-for="(franquia, index) of Franquias"
          :key="index"
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center mb-2">
              <i
                style="margin-right: 6px; color: #82d616"
                :class="
                  franquia.VC1 != ''
                    ? 'fas fa-mobile-alt'
                    : franquia.LDN != ''
                    ? 'fas fa-globe-americas'
                    : 'fas fa-map-marker-alt'
                "
                aria-hidden="true"
              ></i>
              <h6 class="text-sm mb-0">
                {{
                  franquia.VC1 != ""
                    ? "LIGACOES PARA MOVEL"
                    : franquia.LDN != ""
                    ? "LIGACOES LONGA DISTANCIA"
                    : "LIGACOES LOCAIS"
                }}
              </h6>
            </div>
            <div
              class="d-flex flex-column"
              v-if="franquia.nome_franquia != 'ILIMITADO'"
            >
              <span class="mb-1 text-xs">
                <b>Minutos do plano:</b>
                {{ franquia.total_minutos_franquia }}
              </span>
              <span class="mb-1 text-xs">
                <b>Minutos utilizados:</b>
                {{ converterSegundosMinutos(franquia).toFixed(0) }}
              </span>
              <span class="mb-1 text-xs">
                <BarraProgresso
                  :id="index"
                  :porcentagem="calcularPorcentagem(franquia)"
                />
                <b>Você consumiu:</b>
                {{ (calcularPorcentagem(franquia) * 100).toFixed(0) }}%
              </span>
            </div>
            <div class="d-flex flex-column" v-else>
              <span class="text-xs">
                <b>Chamadas ilimitadas </b>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BarraProgresso from "../components/BarraProgresso.vue";

export default {
  name: "Franquias",
  components: {
    BarraProgresso,
  },
  props: {
    Franquias: Object,
  },
  methods: {
    converterSegundosMinutos(franquia) {
      return (
        franquia.total_minutos_franquia - franquia.segundos_disponiveis / 60
      );
    },
    calcularPorcentagem(franquia) {
      let minutos = franquia.segundos_disponiveis / 60;
      return (
        (franquia.total_minutos_franquia - minutos) /
        franquia.total_minutos_franquia
      );
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

html {
  scrollbar-color: #888;
  scrollbar-width: thin;
}
</style>
