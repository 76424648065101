<template>
  <div class="container-contratos mb-2">
    <div class="d-flex align-items-center btn-voltar" @click="EmiteVoltar()">
      <i
        class="bi bi-arrow-left-short"
        style="margin-right: 6px !important"
      ></i>
      <p class="text-sm text-bold" style="margin-bottom: 0">Voltar</p>
    </div>

    <div class="row mt-3">
      <div class="col-md-6 mt-2">
        <CardDadosTelefonia :Telefonia="Telefonia" />
      </div>
      <div class="col-md-6 mt-2">
        <Franquias
          v-if="Telefonia.telefonia?.ramais_franquias?.length != 0"
          :Franquias="Franquias"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6 mt-2">
        <Ramais
          v-if="Telefonia.telefonia?.did?.length != 0"
          :Ramais="Telefonia.telefonia.did"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Franquias from "../components/Franquias.vue";
import Ramais from "../components/Ramais.vue";
import CardDadosTelefonia from "./CardDadosTelefonia.vue";

export default {
  name: "DetalhesTelefonia",
  components: {
    Franquias,
    Ramais,
    CardDadosTelefonia,
  },
  emits: ["voltar"],
  props: {
    Telefonia: Object,
  },
  data() {
    return {
      Franquias: [],
    };
  },
  methods: {
    EmiteVoltar() {
      this.$emit("voltar");
    },
    async EditarArrayFranquias() {
      if (this.Telefonia.telefonia.ramais_franquias.length > 0) {
        let Franquias = [...this.Telefonia.telefonia.ramais_franquias];
        let ProcuraLc = await Franquias.find((franquia) => franquia.LC != "");
        if (!ProcuraLc) {
          Franquias.unshift({
            LC: "",
            LDN: "",
            VC1: "",
            VC2: "",
            VC3: "",
            LI: "ILIMITADO",
            nome_franquia: "ILIMITADO",
          });
        }
        this.Franquias = Franquias;
      }
    },
  },
  watch: {
    Telefonia: {
      handler: "EditarArrayFranquias",
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.btn-voltar:hover {
  cursor: pointer;
}
</style>
