<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Ligacoes</h6>
        </div>
        <div class="col-6 text-end">
          <soft-button color="success" size="sm" variant="outline" @click="AbrirModal"
            >Cadastrar</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Nome
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Funcao
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Status
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(usuario, index) in slicedData" :key="index">
              <td>
                <div class="d-flex px-2 pt-1">
                  <div>
                    <soft-avatar :img="user" size="sm" border-radius="lg" class="me-3" alt="user1" />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ usuario.Nome }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ usuario.Email }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">Monitoramento</p>
                <p class="text-xs text-secondary mb-0">Premium</p>
              </td>
              <td class="align-middle text-center text-sm">
                <soft-badge v-if="usuario.Status == 1" color="success" variant="gradient" size="sm">Ativo</soft-badge>
                <soft-badge v-else color="secondary" variant="gradient" size="sm">Inativo</soft-badge>
              </td>
              <td class="align-middle">
                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip"
                  data-original-title="Edit user" @click="AbrirModalEditar(usuario)">Editar</a>
              </td>
            </tr>
          </tbody>
        </table>

        <CadastraUsuario :modal="Exibemodal" @close="FecharModal"/>
        <EditarUsuario :modal="modalEditar" :usuario="UsuarioSelecionado" @close="FecharModal"/>
      </div>
    </div>

    
  </div>
  <nav aria-label="Page navigation example" v-if="UsuariosStore.getUsuarios().length > 8">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" @click.prevent="changePage(currentPage - 1)" href="#" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item" v-for="page in totalPages" :key="page" :class="currentPage === page ? 'active' : ''">
        <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(currentPage + 1)">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import user from "@/assets/img/useredit.jpg";
import CadastraUsuario from "./CadastraUsuario.vue";
import EditarUsuario from "./EditarUsuario.vue";
import { useAuthStore } from "@/stores/auth.js"
import { useUsuariosStore } from "@/stores/usuarios.js";
import SoftButton from "@/components/SoftButton.vue";
let UsuariosStore;
let AuthStore;


export default {
  name: "Ligacoes",
  data() {
    return {
      user,
      UsuariosStore,
      AuthStore,
      Exibemodal: undefined,
      modalEditar: undefined,
      itemsPerPage: 8,
      currentPage: 1,
      UsuarioSelecionado: {},
    };
  },
  components: {
    SoftAvatar,
    SoftBadge,
    SoftButton,
    CadastraUsuario,
    EditarUsuario,
  },
  methods: {
    AbrirModalEditar(cliente) {
      this.UsuarioSelecionado = {
        Id: cliente.Id,
        Login: cliente.Login,
        Nome: cliente.Nome,
        Email: cliente.Email,
        Status: cliente.Status
      }
      this.modalEditar = true;
    },
    AbrirModal() {
      this.Exibemodal = true;
    },
    async FecharModal(){
      this.modalEditar = false;
      this.Exibemodal = false;
      await UsuariosStore.AtualizaUsuarios(AuthStore.getMe());
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    EditarCliente(cliente){
      console.log(cliente)
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.UsuariosStore.getUsuarios().length / this.itemsPerPage);
    },
    slicedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.UsuariosStore.getUsuarios().slice(start, end);
    },
  },
  setup() {
    UsuariosStore = useUsuariosStore();
    AuthStore = useAuthStore();

    let cliente = AuthStore.getMe()
    UsuariosStore.AtualizaUsuarios(cliente);

    return {
      listaUsuarios: UsuariosStore.getUsuarios(),
    }
  }
};
</script>
