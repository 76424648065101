<template>
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-hidden="true" ref="modalRef">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Cadastrar Usuario</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form role="Login" @submit="(e) => { e.preventDefault() }" class="text-start">
            <div class="mb-1">
              <label>Nome</label>
              <input class="form-control" id="nome" type="text" v-model="login.Nome" placeholder="Usuario"
                aria-label="Nome" />
            </div>
            <div class="mb-1">
              <label>Login</label>
              <input class="form-control" id="login" type="text" v-model="login.Login" placeholder="Usuario"
                aria-label="Usuario" />
            </div>
            <div class="mb-1">
              <label>Senha</label>
              <input class="form-control" id="password" type="password" v-model="login.Senha" placeholder="Senha"
                aria-label="Password" minlength="8" />
            </div>
            <div class="mb-1">
              <label>Email</label>
              <input class="form-control" id="email" type="email" v-model="login.Email" placeholder="Senha"
                aria-label="Email" />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <soft-button color="secondary" size="sm" @click="FecharModal">Fechar</soft-button>
          <soft-button size="sm" color="success" variant="gradient" @click="Cadastrar">Cadastrar</soft-button>
        </div>
      </div>
      {{ AbrirBotao }}
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { apimonitoramento } from "../../boot/axios";
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { ref } from 'vue'

export default {
  emits: ["close"],
  props: {
    modal: Boolean,
  },
  components: {
    SoftButton,
  },
  data() {
    return {
      login: {
        Nome: '',
        Login: '',
        Senha: '',
        Email: '',
      },
    }
  },
  mounted() {
    this.modalCadastro = new bootstrap.Modal(this.$refs.modalRef)
  },
  methods: {
    Cadastrar() {
      try {
        if (this.login.Nome == '' || this.login.Login == '' || this.login.Senha == '' || this.login.Email == '') {
          this.$notify({ type: "error", text: "Informações faltando! Tente novamente.", duration: 5000, });
          return false;
        } else {
          return apimonitoramento
            .post("premium/cadastrar", this.login)
            .then((response) => {
              if (response.data.Cadastrado) {
                this.$notify({ type: "success", text: "Usuario cadastrado", duration: 5000, });
                this.FecharModal()
                this.$emit("close");
              }
            })
            .catch((error) => {
              if (error.response.data.LoginEncontrado) {
                this.$notify({ type: "error", text: error.response.data.msg, duration: 5000, });
              } else {
                console.error(error)
              }
            })
        }
      } catch (error) {
        console.error(error)
      }
    },
    LimpaVariaveis(){
      this.login = {
        Nome: '',
        Login: '',
        Senha: '',
        Email: '',
      }
    },
    AbrirModal() {
      this.modalCadastro.show()
    },
    FecharModal() {
      this.modalCadastro.hide();
      this.LimpaVariaveis();
      this.$emit("close");
    },
  },
  computed: {
    AbrirBotao() {
      return this.modal ? this.AbrirModal() : ''
    }
  },
  setup() {
    const modalRef = ref(null);

    return {
      modalRef
    }
  }
}
</script>