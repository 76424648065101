<template>
  <div class="card bg-transparent shadow-xl">
    <div
      class="overflow-hidden position-relative border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      }"
    >
      <span class="mask" :class="`bg-gradient-${card.background}`"></span>
      <div
        class="card-body position-relative z-index-1 p-4"
      >
        <i class="fas fa-phone-alt text-white" aria-hidden="true"></i>
        <h5 class="text-white mt-4">
          {{ Telefonia.contrato }}
        </h5>
        <div class="d-flex align-items-center mb-5">
          <i
            :class="Telefonia.status == 'A' ? 'far fa-smile' : 'far fa-frown'"
            style="margin-right: 6px"
            class="text-white"
            aria-hidden="true"
          ></i>
          <p class="text-white text-sm mb-0">
            {{ Telefonia.status == "A" ? "Ativo" : "Inativo" }}
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="me-4">
              <p class="text-white text-sm opacity-8 mb-0">Circuito ID</p>
              <h6 class="text-white mb-0">
                {{ Telefonia.telefonia.ramal }}
              </h6>
            </div>
          </div>
          <div class="w-20 d-flex align-items-end justify-content-end">
            <soft-avatar class="w-20 mt-2" :img="logo" alt="logo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import img from "@/assets/img/curved-images/curved14.jpg";
import img1 from "@/assets/img/logos/mastercard.png";
import logo from "@/assets/img/logo.png";

export default {
  name: "MasterCard",
  components: {
    SoftAvatar,
  },
  props: {
    card: {
      type: Object,
      number: String,
      holderName: String,
      expiryDate: String,
      holderText: String,
      expiryText: String,
      background: String,
      default: () => ({
        number: "7852 4594 1122 4562",
        holderName: "Jack Peterson",
        expiryDate: "11/22",
        holderText: "Card Holder",
        expiryText: "Expires",
        background: "dark",
      }),
    },
    Telefonia: Object,
  },
  data() {
    return {
      img,
      img1,
      logo,
    };
  },
};
</script>
