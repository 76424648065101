<template>
  <div class="mb-2">
    <p class="text-sm text-bold" style="margin-bottom: 0">
      Selecione o contrato que deseja visualizar mais informações:
    </p>
  </div>

  <div class="container-contratos mb-2">
    <div
      class="card card-contrato"
      v-for="(contrato, index) in TelefoniaStore.getContratosTelefonia()"
      :key="index"
    >
      <div class="badge-contrato mb-3">
        <div id="badge-telefonia" class="d-flex align-items-center">
          <i class="bi bi-wifi" style="margin-right: 6px !important"></i>
          <p class="text-sm text-bold">Telefone</p>
        </div>
      </div>
      <div>
        <p class="text-xs text-bold">
          {{ contrato.data_ativacao.split("-").reverse().join("/") }}
        </p>
        <p class="text-sm text-bold mt-2">{{ contrato.contrato }}</p>
        <p class="text-xs text-bold" v-if="contrato.telefonia.did.length != 0">
          NUMERO: {{ formatarNumero(contrato.telefonia.did[0].numero) }}
        </p>
        <p class="text-xs text-bold">ID: {{ contrato.id }}</p>
        <p v-if="contrato.endereco != ''" class="text-xs text-bold">
          {{ contrato.endereco }} - Nº{{ contrato.numero }}
        </p>
        <div
          class="d-flex align-items-center"
          :class="contrato.status == 'A' ? 'text-success' : 'text-danger'"
        >
          <i
            :class="
              contrato.status == 'A' ? 'bi-emoji-smile' : 'bi-emoji-frown'
            "
            class="bi me-1 text-xs"
          ></i>
          <p class="text-xs text-bold">
            {{
              contrato.status == "A"
                ? "Ativo"
                : contrato.status == "I"
                ? "Inativo"
                : contrato.status == "P"
                ? "Pré-contrato"
                : contrato.status == "D"
                ? "Desistiu"
                : ""
            }}
          </p>
        </div>
        <div>
          <button
            @click="AbrirContrato(contrato)"
            type="button"
            class="btn w-100 mt-2"
            style="
              background-color: #0d6efd !important;
              color: #fff;
              margin-bottom: 0;
            "
          >
            Ver detalhes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useTelefoniaStore } from "../../stores/telefonia";
const PhoneNumber = require("libphonenumber-js");
let TelefoniaStore;

export default {
  name: "ContratosTelefonia",
  emits: ["enviacontrato"],
  data() {
    return {
      TelefoniaStore,
    };
  },
  methods: {
    formatarNumero(numero) {
      const numeroFormatado = PhoneNumber.format(
        PhoneNumber.parse(numero, "BR"),
        "National"
      );
      return numeroFormatado;
    },
    AbrirContrato(contrato) {
      this.$emit("enviacontrato", contrato);
    },
  },
  setup() {
    TelefoniaStore = useTelefoniaStore();
  },
};
</script>

<style scoped>
.container-contratos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.card {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  flex: 1;
  min-width: 300px;
  max-width: 400px;
}

.card p {
  margin-bottom: 0;
}

.badge-contrato {
  color: #fff;
}

.badge-contrato #badge-telefonia {
  background-color: rgba(23, 166, 4, 0.2);
  color: rgb(23, 166, 4);
  padding: 2px 6px;
  border-radius: 12px;
}

@media (max-width: 1023px) {
  .card {
    min-width: 100%;
    flex-basis: calc(50% - 20px);
  }
}
</style>
