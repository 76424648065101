<template>
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-hidden="true"
    ref="modalRefEditar">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Editar Usuario</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form role="Login" @submit="(e) => { e.preventDefault() }" class="text-start">
            <div class="mb-1">
              <label>Nome</label>
              <input class="form-control" id="EditarNome" type="text" v-model="login.Nome" placeholder="Usuario"
                aria-label="Nome" />
            </div>
            <div class="mb-1">
              <label>Login</label>
              <input class="form-control" id="EditarLogin" type="text" v-model="login.Login" placeholder="Usuario"
                aria-label="Usuario" />
            </div>
            <div class="mb-1">
              <label>Senha</label>
              <input class="form-control" id="EditarSenha" type="password" v-model="login.Senha" placeholder="Senha"
                aria-label="Password" minlength="8" />
            </div>
            <div class="mb-2">
              <label>Email</label>
              <input class="form-control" id="EditarEmail" type="email" v-model="login.Email" placeholder="Senha"
                aria-label="Email" />
            </div>
            <div class="mb-1">
              <soft-switch id="usuarioAtivo" name="usuarioAtivo" :checked="Status" @click="Status = !Status">
                {{ Status ? 'Ativo' : 'Inativo' }}
              </soft-switch>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <soft-button color="secondary" size="sm" @click="FecharModal">Fechar</soft-button>
          <soft-button size="sm" color="success" variant="gradient" @click="Editar">Editar</soft-button>
        </div>
      </div>
      {{ AbrirBotao }}
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import { apimonitoramento } from "../../boot/axios";
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { ref } from 'vue'

export default {
  emits: ["close"],
  props: {
    modal: Boolean,
    usuario: Object
  },
  components: {
    SoftButton,
    SoftSwitch
  },
  data() {
    return {
      login: {},
      Status: undefined,
    }
  },
  mounted() {
    this.modalEditar = new bootstrap.Modal(this.$refs.modalRefEditar)
  },
  methods: {
    Editar() {
      try {
        console.log(this.login.Senha)
        if (this.login.Nome == '' || 
        this.login.Login == '' || 
        this.login.Senha == '' || 
        this.login.Email == '' ||
        this.login.Senha == undefined
        ) {
          this.$notify({ type: "error", text: "Informações faltando! Tente novamente.", duration: 5000, });
          return false;
        } else {
          return apimonitoramento
            .post("premium/editar", {
              Id: this.login.Id,
              Nome: this.login.Nome,
              Login: this.login.Login,
              Senha: this.login.Senha,
              Email: this.login.Email,
              Status: this.Status ? 1 : 0
            })
            .then((response) => {
              if (response.data.Atualizado) {
                this.$notify({ type: "success", text: "Usuario atualizado!", duration: 5000, });
                this.FecharModal()
                this.$emit("close");
              }
            })
            .catch((error) => {
              if (error.response.data.LoginEncontrado) {
                this.$notify({ type: "error", text: error.response.data.msg, duration: 5000, });
              } else {
                console.error(error)
              }
            })
        }
      } catch (error) {
        console.error(error)
      }
    },
    LimpaVariaveis() {
      this.login = {
        Nome: '',
        Login: '',
        Senha: '',
        Email: '',
      }
    },
    AbrirModal() {
      this.modalEditar.show()
      this.login = { ...this.usuario }
      this.Status = this.usuario.Status == 1 ? true : false
    },
    FecharModal() {
      this.modalEditar.hide();
      this.LimpaVariaveis();
      this.$emit("close");
    },
  },
  computed: {
    AbrirBotao() {
      return this.modal ? this.AbrirModal() : ''
    }
  },
  setup() {
    const modalRefEditar = ref(null);

    return {
      modalRefEditar
    }
  }
}
</script>