import { defineStore } from "pinia";
import { apimonitoramento } from "../boot/axios";

export const useUsuariosStore = defineStore("UsuariosStore", {
  state: () => ({
    Usuarios: [],
  }),
  getters: {
    getUsuarios: (state) => () => {
      return state.Usuarios;
    },
  },
  actions: {
    async AtualizaUsuarios(cliente) {
      return await apimonitoramento
        .post("premium/listar", {ClienteId: cliente.ClienteIdIxc})
        .then((response) => {
          this.Usuarios = response.data.Usuario;
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            return error.response.data;
          }
        });
    }
  }
});